
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* Updated to column */
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.top-logo-landing {
  position: absolute;
  margin-left: 10%;
  margin-top: 24px;
}