@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /* Colors */
  --primary-color: #266DF5;
  --secondary-color: #ffffff;
  --dark-gray: #282828;
  --dark-gray-text: #575757;
  --black-main: #161618;
  --ultra-light-gray: #DDE2EC;
  --red-error: #F01000;
  background-color: #DAE7FF;
  text-align: center;
  color:var(--dark-gray)
}

button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin: 0;
  width: 100%;
}

button:hover {
  opacity: 0.9;
}
