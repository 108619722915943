.form-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 12px;
}

.form-group label {
  font-weight: 400;
  align-self: flex-start;
}

.input-field {
  display: flex;
  position: relative;
}

.input {
  display: flex;
  flex: 1;
  border-radius: 8px;
  padding: 14px 18px;
  background: var(--ultra-light-gray);
  border: none;
}

.clear-icon {
  border: none;
  background-color: transparent;
  position: absolute;
  right: 0px;
  top: 3px;
  width: auto;
}

.input-error {
  border: 1px solid var(--red-error);
}