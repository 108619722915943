.controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
}

.channel-buttons {
    width: 100px;
    height: 50px;
    color: white;
    border-radius: 10px;
    margin-top: 20px;
    margin-right: 20px;
}

.channel-buttons.black {
    background-color: rgba(0, 0, 0, 0.8);
}

.channel-buttons.red {
    background-color: rgba(255, 0, 0, 0.8);
}

input[type="range"] {
    margin: 0 10px;
    flex: 1;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
    width: 12px;
    height: 12px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
}