

.App-top-bar {
    background-color: #9900FF;
    padding: 10px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.App-logo-container {
    width: 100px; /* Adjust the size of the circle as needed */
    height: 100px; /* Adjust the size of the circle as needed */
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.App-link {
    font-size: 16px;
    color: #01A1F1;
}


.App-verify-box {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px; /* Set the maximum width for the signup box */
    margin: 0 auto; /* Center the signup box horizontally */
}

form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

label {
    font-size: 14px;
    margin-bottom: 4px;
    color: #000;
}

input {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
