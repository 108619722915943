.videos {
  align-items: center;
  background: black;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-start;
}

.videos-header {
  display: flex;
  flex-direction: column;
  height: 15vh;
  justify-content: space-between;
  max-width: 80%;
  min-width: 1024px;
  padding-top: 2rem;
  position: relative;
  width: 100%;
}

.top-nav {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  width: 100%;
}

.videos-content {
  border: 1px solid white;
  max-width: 80%;
  min-width: 1024px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 70vh;
}

.App-header {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  font-size: 16px;
  color: #ffffff;
}

