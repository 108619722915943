.profile {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-start;
}

.profile-header {
  display: flex;
  flex-direction: column;
  height: 15vh;
  justify-content: space-between;
  max-width: 80%;
  min-width: 1024px;
  padding-top: 2rem;
  position: relative;
  width: 100%;
}

.top-nav {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  width: 100%;
}

.profile-content {
  border: 1px solid white;
  max-width: 80%;
  min-width: 1024px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 70vh;
}

.barLogo-profile {
  width: 200px; /* Adjust the width as needed */
  position: absolute;
  top: 20px; /* Adjust the top position as needed */
  left: 10%; /* Adjust the left position as needed */
}

.barProfile {
  display: flex;
  align-items: center;
}

.profile-title {
  font-weight: bold;
  color: white; /* Adjust the color as needed */
}
