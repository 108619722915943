.logo {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.logo span {
  color: var(--dark-gray-text);
  text-align: center;
  font-family: Roboto;
  font-size: 20.935px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 8px;
}

.logo--inline {
  justify-content: flex-start;
  flex-direction: row;
  gap: 10px;
}

.logo--inline span {
  font-size: 16px;
  margin-bottom: 2px;
}