.dashboard {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Adjust to minimum height of viewport */
  justify-content: flex-start;
}

.dashboard-header {
  display: flex;
  flex-direction: column;
  height: auto; /* Adjust to fit content */
  justify-content: space-between;
  max-width: 80%;
  min-width: 500px;
  padding-top: 2rem;
  position: relative;
  width: 100%;
}

.top-nav {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  width: 100%;
}

.title-container h1 {
  font-size: 2.5rem;
  margin: 0;
}

.admin-theme-dash {
  /* Admin color styles */
  background-color: #DAE7FF;
}

.admin-theme-order {
  /* Admin color styles */
  background-color: white;
}

.admin-theme-dash-content{
  background-color: #EDF4F9;
}

.admin-theme-options-container{
  background-color: white;
  border-radius: 15px 15px 0 0;
}

.admin-theme-options-container::after{
  border: transparent;
}

.admin-theme-sorting{
  color: black;
}

.menu-item.admin-theme-option{
  background-color: transparent;
  font-weight: '600';
  padding: 1.5rem;
  /* Reduce spacing between clickable divs */
  cursor: pointer;
  text-align: left;
  /* Align text to the left */
  color: grey;
  border-color: transparent;
  border-style: solid;
  transition: all 0.3s ease;
}

.menu-item.admin-theme-option:Hover{
  color: black;
  font-weight: bold;
}

.menu-item.selected.admin-theme-option{
  color: black;
  font-weight: bold;
}


.dashboard-content {
  border: 1px solid transparent;
  max-width: 80%;
  min-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 70vh; 
  border-radius: 15px;
  background-color: #EBF2FF;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* Updated to column */
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  font-size: 16px;
  color: #ffffff;
}

.App-buttons-dropdown {
  display: inline-block;
  cursor: pointer;
  color: white;
}

.App-buttons-dropdown>div {
  display: inline-block;
  border-color: #000;
}

.profile-button-container {
  display: inline-block;
}

.dropdown-options {
  background-color: white;
  border-radius: 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: black;
  justify-content: space-between;
  margin: 0;
  padding: 10px;
  position: absolute;
  right: 0;
  text-align: right;
  top: 2rem;
  transition: background-color 0.3s;
  width: auto;
  z-index: 1;
}

.dropdown-options.active {
  background-color: white;
  color: #000;
}

.dropdown-button:hover {
  background-color: transparent; 
  color: black; 
}

.dropdown-options>div {
  cursor: pointer;
  padding: 8px;
}

.App-buttons.active .dropdown-content {
  display: block;
  background-color: white;

  button {
    color: black;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin: 10px;
    /* Added margin for spacing */
    width: 100%;
    background-color: white;
  }
}

.dropdown-contents {
  position: absolute;
  top: 55%;
  right: 0;
  background-color: white;
  border-radius: 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 200px;
  z-index: 2;
}

.barProfile {
  width: 10%;
  position: absolute;
  top: 1%;
  right: 5%;
  display: flex;
  /* Updated to flex */
  align-items: center;
  /* Align items vertically in flex container */
}

.calendar {
  color: white;
}

.Dash-title {
  margin: auto;
  color: white;
  font-size: 90px;
  /* Correct the font size declaration */
  text-align: left;
  position: absolute;
  left: 9%;
  width: 12%;
  top: 10%;
}

.logo-container {
  display: flex;
  width: 250px;
}

.options-container {
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 20px;
  background-color: #ffffff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.options-container::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  /* Set the height of the grey bar */
  width: 100%;
  /* Set the initial width of the grey bar to cover the whole container */
  border-bottom: 1px solid #575757;
}

.selected::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
}

.options-container button {
  text-align: left;
}

.selected {
  background-color: transparent;
  color: white;
}

.product-order-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #3A3A3A;
  border-radius: 5px;
  max-width: 100%; /* Ensure items don't exceed parent container width */
}

.product-order-item .extra-padding {
  padding-left: 4.5%;
  /* Add 5% padding to the left */
}

.product-order-item:hover {
  background-color: #7163FE;
}

.product-order-item h3 {
  margin: 0;
  font-size: 1rem; /* Adjust font size as needed */
}

.product-order-item p {
  margin: 5px 0;
  font-size: 0.9rem; /* Adjust font size as needed */
}

.sorting-options {
  padding-top: 2%;
  display: flex;
}

.menu-item {
  font-weight: '600';
  padding: 1.5rem;
  /* Reduce spacing between clickable divs */
  cursor: pointer;
  text-align: left;
  /* Align text to the left */
  color: grey;
  border-color: transparent;
  border-style: solid;
  border-width: 0 0 1px 0;
  transition: all 0.3s ease;
  background-color: transparent;
}

.menu-item:hover {
  background-color: transparent;
  color: white;
}

.menu-item.selected {
  border-color: var(--primary-color);
  z-index: 1;
  color: black;
  font-weight: bold;
}

.sorting-options button {
  margin-right: 10px;
  /* Add some margin between buttons for spacing */
  position: relative;
  padding: 5px 20px 5px 10px;
  /* Adjust padding as needed */
  background-color: transparent;
  text-align: left;
}

.sorting-options button:hover {
  margin-right: 10px;
  /* Add some margin between buttons for spacing */
  position: relative;
  padding: 5px 20px 5px 10px;
  /* Adjust padding as needed */
  background-color: transparent;
  text-align: left;
  /* Align text to the left */
}

.sorting-options button::after {
  content: '';
  border-style: solid;
  border-width: 5px 5px 0;
  /* Adjust the size of the triangle */
  border-color: transparent transparent transparent transparent;
  position: absolute;
  top: 50%;
  right: 5px;
  /* Adjust the position of the triangle */
  transform: translateY(-50%);
}

.sorting-options button.asc::after {
  border-width: 0 5px 5px 5px;
  /* Adjust the size of the upward triangle */
  border-color: transparent transparent transparent transparent;
}

.sorting-options button.desc::after {
  border-width: 5px 5px 0 5px;
  /* Adjust the size of the downward triangle */
  border-color: transparent transparent transparent transparent;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: auto;
  padding: 1.5rem;
  align-self: flex-end center;
}

.pagination {
  display: flex;
  justify-content: center;
}

.pagination-button {
  margin: 0 5px;
  padding: 8px 12px;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.3s;
  color: black;
}

.pagination-button:hover {
  background-color: #FFF;
  color: #000;
}

.pagination-button.active {
  background-color: #3A3A3A;
  color: #fff;
}

.clear {
  background-color: transparent;
}

.clear:hover {
  background-color: transparent;
}
.status-display {
  border-radius: 50px;
  font-weight: bold;
  padding-left: 1%;
  padding-right: 1%;
  width: 120px;
  text-align: center;
  background: white;
  color: black;
  min-width: 50px;
}
.status-display:hover {

  background: white;
  color: black;

}

/* Comment this back in to change colors of status button when needing to be implemented*/

.status-display.pricing{
  background-color: #266DF5;
  color: white;
  border: 1px solid #266DF5;
}

.status-display.paid{
  background-color: #5ec944;
  color: white;
  border-color: white;
  border: 1px solid #5ec944;
}

.status-display.open{
  background-color: #5ec944;
  color: black;
  border: 1px solid #5ec944;
}

.status-display.pending{
  background-color:transparent;
  color: #FFC107;
  font-weight: bold;
  border: 1px solid #FFC107;
}

.status-display.completed{
  background-color:transparent;
  color: #6A6A6A;
  font-weight: bold;
  border-color: #6A6A6A;
  border: 1px solid;
}

.status-display.refunded{
  background-color:transparent;
  color: #f52626;
  font-weight: bold;
  border: 1px solid #f52626;
}

.status-display.cancelled{
  background-color: #f52626;
  color: white;
  border: 1px solid #f52626;
}

.search-container {
  display: flex;
  background-color: transparent;
  color: black;
  border-color: transparent;
  align-items: center;
  margin-left: auto;
  padding-right: 1.5rem;
}

.search-container input {
  color: white;
  background: transparent;
  border-color: transparent;
  margin-right: .5rem;
}

.search-icon {
  font-size: 16px;
  /* Adjust the font size as needed */
  color: #fff;
  /* Set the color of the icon */
  cursor: pointer;
  /* Add a cursor to indicate it's clickable */
}

.table-container {
  max-width:100%;
  min-width: 100px;
  display: flex;
  justify-content: center; /* Align children horizontally at the center */
  align-items: center; /* Align children vertically at the center */
  overflow-x: auto;
}

.orders-table {
  width: auto;
  min-width: 940px;
  max-width: 100%;
  border-collapse: separate;
  border-spacing: 0px 10px;
  table-layout: fixed;
}

.orders-table th,
.orders-table td {
  text-align: left;
  color: black;
  padding: 15px;
  white-space: nowrap; /* Prevent line breaks within table cells */
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: normal;
  cursor: pointer;
}

.orders-table td:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.orders-table td:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.orders-table tr {
  background-color: white;
  transition: all 0.2s ease;
}

.orders-table tr:hover {
  background-color: var(--primary-color);
}

.orders-table tr:hover td {
  color: white;
}

.tbody.tr:hover{
  background-color: var(--primary-color);
}

.page-title.admin-title{

  color: black;
}

.modal {
  color: white;
  background-color: #3A3A3A;
  border-color: 3A3A3A;
  border-radius: 15px;
  width: 50%;
  height: 50%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 10;
}

.modal-close-btn {
  background-color: black;
  color: #fff;
}

.action-btn:hover {
  color: black;
}

.admin-date{
  color:#000;
}

.admin-link{
  color: #000;
  font-weight: bold;
}

.table-btn-head {
  background-color: transparent;
}

.orders-table td:nth-child(1), th:nth-child(1) {
  width: 15%;
}

.orders-table th:nth-child(1) {
  text-align: left;
}

.orders-table td:nth-child(2), th:nth-child(2) {
  width: 15%;
}

.orders-table td:nth-child(2) {
  text-align: center;
}

.orders-table td:nth-child(3), th:nth-child(3) {
  width: 25%;
}

.orders-table td:nth-child(4), th:nth-child(4) {
  width: 15%;
}

.orders-table td:nth-child(5), th:nth-child(5) {
  width: 10%;
}
.orders-table td:nth-child(5) {
  text-align: right;
}

.orders-table td:nth-child(6), th:nth-child(6) {
  width: 10%;
}
.orders-table td:nth-child(6) {
  text-align: right;
}

.orders-table td:nth-child(7), th:nth-child(7) {
  width: 10%;
}
.orders-table th:nth-child(7) {
  text-align: center;
}

.orders-table tr:hover td:nth-child(7) {
  color: black;
}