.screen-container {
  margin: 0 auto;
  position: relative;
}

.screen-logo {
  position: absolute;
  top: 26px;
  margin-left: 10%;
}

.screen-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
}

.screen-content {
  height: 60%;
  width: 380px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
}
