.welcome-label {
  color: var(--dark-gray-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.32px;
}

.login-logo {
  margin-top: 36px;
}

.app-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 54px;
  gap: 8px;
}

.need-help {
  padding-top: 40px;
  padding-bottom: 20px;
}

.modal-label {
  font-size: 32px;
  font-weight: 700;
  color: var(--black-main);
  text-align: center;
  font-family: Roboto;
}

.modal-subtitle {
  color: black;
  font-size: 15px;
  margin-top: 40px;
}

.modal-form {
  color: black;
  text-align: left;
  margin-top: 20px;
}

.modal-input {
  color: black;
  background-color: white;
}

.modal-textarea {
  resize: none;
  color: black;
  background-color: white;
  height: auto;
  padding-bottom: 20%;
}

.modal-options {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-top: 16px;
}
