.button--base {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  padding: 15px 20px;
}

.button--secondary {
  background-color: transparent;
  color: var(--dark-gray-text);
  border: 1px solid #000;
}

.button--underline {
  background-color: transparent !important;
  color: var(--black-main);
  text-align: center;
  font-style: normal;
  font-weight: 700;
  text-decoration-line: underline;
}

button:hover {
  opacity: 0.9;
}
  