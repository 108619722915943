/* Modal Overlay Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

}
  
/* Modal Content Styles */
.modal-content {
  background: #fff;
  padding: 20px;
  border-color: transparent;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: auto;
  position: relative;
  padding: 30px 100px 70px;
  max-width: 360px;
}
  
/* Close Button Styles */
.close-button {
  position: absolute;
  top: 0px;
  right: 20px;
  z-index: 100;
  cursor: pointer;
  border: none;
  font-size: 16px;
  width: 20px;
  background-color: transparent;
}

.modal-textarea {
  resize: none;
  width: 100%;
  height: 20%;
}