.home-content {
  display: flex;
  flex-direction: column;
  padding: 60px 20px;
}

.enter-button {
  margin-top: 36px;
  width: 100%;
}
