.order {
  align-items: center;
  background: #DAE7FF;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-start;
}

.order-header {
  display: flex;
  flex-direction: column;
  height: 15vh;
  justify-content: space-between;
  max-width: 80%;
  min-width: 500px;
  padding-top: 2rem;
  position: relative;
  width: 100%;
}

.top-nav {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  width: 100%;
}

.order-content {
  border: 1px solid white;
  max-width: 80%;
  min-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 70vh;
  background-color: white;
  color: black;
  border-radius: 15px;
}

.order-content-header {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  align-items: center;
}

.status-container {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.order-info {
  display: flex;
  flex-direction: column;
  width: 75%;
  overflow: hidden;
}

.order-content-details {
  position: relative;
  flex: 1;
  margin: 0 1.5rem;
  padding: 1.5rem;
  border: 1px solid white;
  display: flex;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  min-height: 250px;
  background-color: #EDF4F9;
}

.pricing-container {
  border: 2px solid white;
  width: 25%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: white;
  border-radius: 15px;
}

.pricing-container h1,
.pricing-container h2,
.pricing-container div,
.pricing-container button {
  margin-top: 0;
  margin-bottom: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.barProfile {
  width: 10%;
  position: absolute;
  top: 1%;
  right: 5%;
  display: flex;
  /* Updated to flex */
  align-items: center;
  /* Align items vertically in flex container */
}

.OrderLogoContainer {
  display: flex;
  align-items: center;
}

.editorButton {
  align-items: center;
  background-color: #266DF5;
  color: white;
  margin: 1rem auto;
}

.status-button {
  background-color: white;
  color: black;
  border-radius: 50px;
  text-align: center;
  padding: 10px 30px;
  width: fit-content;
}

.order-details h4,
.order-details div {
  /* Add these styles to align content to the right */
  position: relative;
  right: 0;
}

.status-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1rem;
}

.order-options-container {
  display: flex;
  position: relative;
}

.order-options-container button {
  margin-right: 10px;
  margin: 0;
  width: fit-content;
}

.order-number-list {
  list-style-type: decimal;
  margin: 0;
  padding: 0;
}

.order-details {
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  flex: 1;
  width: 95%
}

.order-details-list {
  list-style-type: decimal;
  padding: 0;
}

.order-details .circle-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.order-details .circle-list li {
  counter-increment: circle-counter;
  margin-bottom: 20px;
  color: black;
}

.order-details .circle-list li:before {
  content: counter(circle-counter);
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.5em;
  border: 1px solid black;
  border-radius: 50%;
  text-align: center;
  line-height: 1.5em;
  color: black;
}

.offer-sender {
  border: 1px solid #161618;
  border-radius: 5px;
  text-align: right;
  padding: 1rem;
}

.order-duration {
  color: #FFF;
}

.deco-bar-order {
  background-color: #3A3A3A;
  width: 145%;
  height: 2px;
  position: absolute;
  top: 97%;
}

.smallButtonDash {
  padding: .5rem 0;
  margin-right: 1rem !important;
  background-color: transparent;
  position: relative;
  color: grey;
  border-bottom: 2px solid transparent;
  border-radius: 0;
  transition: all 0.3s ease;
}

.smallButtonDash.selected {
  background-color: transparent;
  position: relative;
  color: #000;
  border-color: var(--primary-color);
  z-index: 1;
}

.smallButtonDash:hover {
  background-color: transparent;
  color: black;
}

.order-strong {
  color: var(--dark-gray);
}

h2 {
  color: var(--dark-gray);
  font-weight: 600;
}

.status-button.pricing{
  background-color: #266DF5;
  color: white;
}

.status-button.open{
  background-color: #5ec944;
  color: black;
}

.status-button.pending{
  background-color:transparent;
  color: #FFC107;
  font-weight: bold;
  border-color: #FFC107;
  border: 2px solid;
}

.status-button.completed{
  background-color:transparent;
  color: #6A6A6A;
  font-weight: bold;
  border-color: #6A6A6A;
  border: 2px solid;
}

.status-button.cancelled{
  background-color: #f52626;
  color: white;
}

.status-button.paid{
  background-color: #5ec944;
  color: white;
  border-color: white;
  border: 1px solid;
}